.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.makeStyles-textfield-41 .Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.26) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3a70d4 !important;
}

.noScroll {
    overflow: hidden !important;
}

.heightFitContent {
    height: auto !important;
    padding-bottom: 0px !important;
}
