/* Scrollbar */
html,
.elementScrollBar {
    --scrollbarBG: #33333338;
    --thumbBG: rgb(143 142 142 / 95%);
    scroll-behavior: smooth;
}

.elementScrollBarOpacity {
    --scrollbarBG: #333333e3;
    --thumbBG: rgba(255, 255, 255, 0.6);
}

.mappingInfoModalScrollBar {
    --scrollbarBG: rgba(255, 255, 255, 0.9);
    --thumbBG: #bdbdbd;
}

.integrationBuilderStepFieldsScrollBar {
    --scrollbarBG: #20202038;
    --thumbBG: rgba(255, 255, 255, 0.2);
}

body::-webkit-scrollbar,
.elementScrollBar::-webkit-scrollbar {
    width: 11px !important;
    height: 11px !important;
}

.elementScrollBarMedium::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
}

.integrationBuilderScrollBar::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.integrationBuilderScrollBar::-webkit-scrollbar,
.integrationBuilderStepFieldsScrollBar::-webkit-scrollbar {
    width: 7.5px !important;
}

.mappingInfoModalScrollBar::-webkit-scrollbar {
    width: 10px;
}

.mappingTreeViewScrollbar::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

body,
.elementScrollBar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track,
.elementScrollBar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 2px;
}

body::-webkit-scrollbar-thumb,
.elementScrollBar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 4px;
    border: 1px solid var(--scrollbarBG);
}

.elementScrollBar::-webkit-scrollbar-thumb {
    border-right-width: 3px;
    border-radius: 4px 5px 5px 4px;
}

.integrationBuilderScrollBar::-webkit-scrollbar-thumb {
    border-width: 4px;
}

.integrationBuilderStepListScrollBar::-webkit-scrollbar-thumb {
    border-width: 0px;
}

.elementScrollBarBorderRadius::-webkit-scrollbar-track {
    border-radius: 5px;
}
/* Scrollbar Ends */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noSelect,
.noSelectContainer div,
.noSelectContainer p,
.noSelectContainer span,
.noSelectContainer h2,
.noSelectContainer h3,
.noSelectContainer h4,
.noSelectContainer h5,
.noSelectContainer h6 {
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.burgerMenu {
    width: 30px;
    height: 30px;
    background: rgb(79 79 79);
    cursor: pointer;
}

.burgerMenu svg {
    padding: 1px;
}

.burgerMenuSelected {
    width: 30px;
    height: 30px;
    background: #7b7b7b;
}

.versionedDocumentSelector-root {
    display: flex;
    position: relative;
    border: 1px solid #CECECE;
    border-radius: 10px;
    color: #333;
    margin: 15px 0;
    padding: 10px;
    cursor: default;
    user-select: none;
}

.versionedDocumentSelector-error {
    border: 1px solid red;
}

.versionedDocumentSelector-disabled {
    background: #F2F2F2;
    color: #888 !important;
}

.versionedDocumentSelector-disabled .versionedDocumentSelector-selectedElement div:nth-child(2) {
    color: #AAA !important;
}

.versionedDocumentSelector-disabled:hover {
    border: 1px solid #E0E0E0 !important;
}

.versionedDocumentSelector-root:hover {
    border: 1px solid blue;
}

.versionedDocumentSelector-root .versionedDocumentSelector-selectedElement div:nth-child(2) {
    color: #666;
    font-style: italic;
    font-size: .9em;
}

.versionedDocumentSelector-root .versionedDocumentSelector-selectedElement svg {
    fill: #666;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
}

/* .noSelectContainer button, 
.noSelectContainer input {
    cursor: default;
} */
